/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages

        // home page nav
        $(".home-nav-primary > li").mouseenter(function() {
          var subNav = "." + $(this).data("sub-nav");
          $(this).addClass("active").removeClass("inactive");
          $(this).siblings().addClass("inactive").removeClass("active");
          $('.home-sub-nav-wrap').removeClass("active");
          $(subNav).addClass("active");
        });
        $(".header-lower-home").mouseleave(function() {
          $(this).find('li').removeClass("active").removeClass("inactive");
          $('.home-sub-nav-wrap').removeClass("active");
        });

        // Mobile nav toggle
        $('.mobile-nav-toggle').click(toggleMobileNav);

        // Safety message dismissal
        $('#safety-message .dismiss').click(function(){
          dismissSafetyMessage();
        });

        // Safety message translation
        $('.safety-translation-menu span').click(function(){
          var lang = $(this).attr('lang');
          $(this).parent().siblings().find('span').removeClass('active');
          $(this).addClass('active');
          safetyMessageTranslate(lang);
        }); 

        // Services translation
        $('.services-translation-menu span').click(function(){
          var lang = $(this).attr('lang');
          $(this).parent().siblings().find('span').removeClass('active');
          $(this).addClass('active');
          servicesMessageTranslate(lang);
        }); 

      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired

        // Show safety message if dismissal cookie not present
        if (!Cookies.get('dismiss-safety')) {
          setTimeout(showSafetyMessage, 1000);
        }

        // expand tab if linked with anchor hash
        if (window.location.hash) {
          $(window.location.hash).find('.collapse').collapse('show');
        }

      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

  function showSafetyMessage() {
    $('body').addClass('show-safety-message');
    $("#safety-message").slideDown(300);
  }

  function dismissSafetyMessage() {
    $("#safety-message").slideUp(300, function(){
      $('body').removeClass('show-safety-message');
      Cookies.set('dismiss-safety', 'true', { expires: 7 });
    });
  }

  function safetyMessageTranslate(lang) {
    if ($(".safety-message .translated-text:not(." + lang + "):visible").length) {
      $(".safety-message .translated-text:not(." + lang + "):visible").slideUp(200, function(){
        $(".safety-message .translated-text." + lang).slideDown(200);
      });
    } else {
      $(".safety-message .translated-text." + lang).slideDown(200);
    }
  }

  function servicesMessageTranslate(lang) {
    if ($(".services-translation-message .translated-text:not(." + lang + "):visible").length) {
      $(".services-translation-message .translated-text:not(." + lang + "):visible").slideUp(200, function(){
        $(".services-translation-message .translated-text." + lang).slideDown(200);
      });
    } else {
      $(".services-translation-message .translated-text." + lang).slideDown(200);
    }
  }

  function toggleMobileNav() {
    if ($('body').hasClass('show-mobile-nav')) {
      $('body').removeClass('show-mobile-nav');
    } else {
      $('body').addClass('show-mobile-nav');
    }
  }

})(jQuery); // Fully reference jQuery after this point.
